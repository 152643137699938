<template>
  <TheHeader></TheHeader>
  <div class="container">
    <slot class="container"></slot>
  </div>
  <TheFooter></TheFooter>
</template>

<style lang="scss" scoped>
.container {
  width: 100%;
  flex-grow: 1;
  margin-top: 54px;
}

@media screen and (min-width: 960px) {
  .container {
    margin-top: 64px;
  }
}
</style>

<style lang="scss">
section {
  max-width: 1200px;
  margin: 0 auto;
}
</style>